var wasMobile = $(window).width() < 768;

/**
 * Loads/reloads video based on width of window
 */
function loadVideo() {
    var $videoContainer = $('.hero-banner__video');

    if ($videoContainer.length > 0) {
        var isMobile = $(window).width() < 768;
        var src = $videoContainer.data(isMobile ? 'mobile' : 'desktop');
        if (!src || src === '' || (isMobile && $videoContainer.data('skipMobile'))) {
            return;
        }
        var $iframe = $('<iframe/>', {
            src: src,
            frameborder: '0',
            allow: 'autoplay; fullscreen; picture-in-picture; clipboard-write'
        });
        $videoContainer.find('.hero-banner__video__container').html($iframe);
    }
}

/**
 * Pauses video based on type of player
 * @param {JQuery} $videoContainer - The DOM element
 */
function pauseVideo($videoContainer) {
    if ($videoContainer && $videoContainer[0]) {
        if ($videoContainer.hasClass('hero-banner__video__container--hq')) {
            var $videoElement = $videoContainer.find('video');
            var newMode = $videoElement.get(0).paused ? 'play' : 'pause';
            $videoElement.trigger(newMode);
        } else {
            var video = $($videoContainer)[0];
            var player = new Vimeo.Player(video);
            player.getPaused().then(function (paused) {
                if (paused) {
                    player.play();
                } else {
                    player.pause();
                }
            });
        }
    }
}

module.exports = function () {
    loadVideo();

    // Only changes the video source if we need to switch between mobile and desktop
    $(window).on('resize', function () {
        var willBeMobile = $(window).width() < 768;
        if (wasMobile !== willBeMobile) {
            loadVideo();
        }
        wasMobile = willBeMobile;
    });

    $('.hero-banner .content-wrapper').on('click', function () {
        if ($(this).data('hasLink')) {
            return;
        }

        var $video = $(this).closest('.hero-banner').find('.hero-banner__video');
        var containerClass = $(window).width() < 768 && $video.data('skipMobile') ? '.hero-banner__video__container--hq' : '.hero-banner__video__container--standard';
        pauseVideo($(this).closest('.hero-banner').find(containerClass));
    });
};
